'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:Routes

 # @description

###
angular
  .module 'lpaData'
  .factory 'Routes', [
      'Restangular'
      (Restangular) ->
        Restangular.service('routes')
    ]
